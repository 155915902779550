<template>
  <div class="feed-back-wrapper">
    <div class="feed-back-form">
      <!-- <router-link class="historical-feedback" to="/historicalFeedback">历史反馈</router-link> -->
      <div class="qrCode">
        <!-- <img v-if="qrCodeImg" :src="qrCodeImg" alt />
        <p>
          如果您有更多的意见和想法
          <br />欢迎添加我们的客服微信！
        </p> -->

        <p class="logOff" v-if="userInfo && userInfo.Phone" @click="cancellation">
          注销账户申请
        </p>
      </div>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="desc">
          <el-input type="textarea" placeholder="在这里输入您的反馈和建议" v-model="form.desc" maxlength="300" rows="12"
            show-word-limit></el-input>
        </el-form-item>
        <el-form-item>
          <h3>
            用户ID：{{ ConsumerId }}
            <span class="copy" v-clipboard:copy="ConsumerId" v-clipboard:success="onCopy"
              v-clipboard:error="onError">复制</span>
          </h3>
        </el-form-item>
        <el-form-item>
          <h3>
            机器码：{{ machineCode }}
            <span class="copy" v-clipboard:copy="machineCode" v-clipboard:success="onCopy"
              v-clipboard:error="onError">复制</span>
          </h3>
        </el-form-item>
        <el-form-item>
          <h3>
            网吧ID：{{ internetBarID }}
            <span class="copy" v-clipboard:copy="internetBarID" v-clipboard:success="onCopy"
              v-clipboard:error="onError">复制</span>
          </h3>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit('form')">提交</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 申请账号注销 -->
    <el-dialog title="账号注销申请" :visible.sync="dialogVisible" width="600px" custom-class="visible-cancellation"
      :close-on-click-modal="false">
      <div>
        <div>亲爱的玩家：</div>
        <p>
          如您需要进行蚂蚁特权的账号注销，请在本页面进行相关操作。为保证您的账号和财产安全，在您提交的注销申请生效前，请您确保您已满足以下条件，蚂蚁特权团队也有权进行验证：
        </p>
        <p>1、账号处于安全状态 账号为正常使用中且无被封等风险；</p>
        <p>
          2、平台及应用收益已结清或得到妥善处理
          蚂蚁特权账号注销会影响到蚂蚁特权及同一账号只能从蚂蚁特权登录的应用，请确保平台及相应应用的收益已结清或得到妥善处理，收益包括但不限于平台及应用内的应用权限、会员权限等，若未处理，视为您自愿放弃该等收益；
        </p>
        <p>
          3、组织创建者等关系解除确保组织创建者等关系解除；财产结清，请确保您在平台上的资金已提现，交易市场上架的物品已下架；
        </p>
        <p>4、账号无任何纠纷包括投诉举报、被投诉举报、仲裁、诉讼等纠纷；</p>
        <p>
          5、确保您在平台的订单已完成发货以及退款流程已结束；其他网站的账号解绑该蚂蚁特权账号已经解除与网站的授权登录或绑定关系；
        </p>
        <div class="check-box">
          <el-checkbox v-model="checked">
            申请注销即表示你自愿放弃账号内所有虚拟资产并同意
            <a href="javascript:;" @click="linkTo">《蚂蚁特权账号注销协议》</a>
          </el-checkbox>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="apply">我已清楚风险 申请注销</el-button>
      </span>
    </el-dialog>

    <!-- 申请确认注销协议验证码弹窗 -->
    <el-dialog title="账号注销" :visible.sync="checkApplyDialog" width="410px" custom-class="visible-cancellation"
      :close-on-click-modal="false">
      <p>
        为了保障你的账号安全，我们需要核实是您本人操作。您绑定的手机号：{{
          userInfo &&
          userInfo.Phone &&
          userInfo.Phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
        }}
      </p>
      <el-form :model="form">
        <el-form-item class="verification-code">
          <el-input v-model="form.code" placeholder="请输入验证码" autocomplete="off">
            <i slot="prefix">
              <img src="@/assets/images/login/verificationCode.png" />
            </i>
          </el-input>
          <el-button :disabled="isDisable" @click="_GetPhoneCode">{{
            computeTime > 0 ? `已发送(${computeTime}S)` : "获取验证码"
          }}</el-button>
        </el-form-item>
        <el-form-item>
          <a class="login" href="#" @click="_ConfirmLogout">确认注销</a>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ProposalAdd, GetPhoneCode, LogOff, GetNavigationList } from "@/api";
import cMethods from "@/util/cMethods.js";

export default {
  name: "FeedBack",
  computed: {
    ...mapGetters(["userInfo", "netBarName", "netBarId", "isLogin"]),
  },
  data() {
    return {
      checked: true,
      qrCodeImg: "",
      dialogVisible: false,
      checkApplyDialog: false,
      computeTime: 0, // 计时的时间
      isDisable: false,
      machineCode: "",
      internetBarID: "",
      ConsumerId: "",
      intervalId: null,
      form: {
        problem: "",
        orderInfo: "",
        desc: "",
      },
      rules: {
        problem: [
          { required: true, message: "请选择您的建议", trigger: "change" },
        ],
        desc: [
          {
            required: true,
            message: "在这里输入您的反馈和建议",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    if (this.$route.query.orderNumber) {
      this.form.orderInfo = this.$route.query.orderNumber;
    }
    // 用户ID
    if (this.userInfo) {
      this.ConsumerId = this.userInfo.ConsumerId + "";
    }
    // 售后客服微信号（二维码）
    this._GetNavigationList(4);

    // 获取机器码
    this.machineCode =
      cMethods.FormGetMachineCode() ||
      cMethods.Extern_Form_jsComInterface_GetMachineCode();
    // 获取网吧ID
    this.internetBarID =
      cMethods.GetNetBarID() ||
      cMethods.Extern_Form_jsComInterface_GetNetbarId();
  },
  methods: {
    // 获取广告图
    async _GetNavigationList(type) {
      let params = {
        type: type,
      };
      let res = await GetNavigationList(params);
      if (res.StatusCode) {
        if (res.Object && res.Object.length) {
          this.qrCodeImg = res.Object[0].ImgUrl;
        }
      }
    },
    // 账号注销
    cancellation() {
      if (this.userInfo && this.userInfo.ConsumerId) {
        if (this.userInfo.State == 2) {
          this.$message({
            message: "您存在严重违规行为，禁止注销",
            type: "error",
          });
          return false;
        }
        this.dialogVisible = true;
      } else {
        eventBus.$emit("getQrCode");
      }
    },
    // 申请
    apply() {
      if (this.checked) {
        this.dialogVisible = false;
        this.checkApplyDialog = true;
      } else {
        this.$message({
          type: "error",
          message: "请阅读并确认勾选蚂蚁特权账号注销协议",
        });
      }
    },
    linkTo(item) {
      cMethods.OpenIeUrl(
        "http://www.mayidianjing.com/Content/html/logOff.html"
      );
    },
    // 提交意见反馈
    onSubmit(formName) {
      if (this.isLogin) {
        if (sessionStorage.getItem("Phone")) {
          this.$refs[formName].validate(async (valid) => {
            if (valid) {
              // this.$statistic(["B", "意见反馈", "点击", "提交"]);
              this.form.problem = "其他";
              let params = {
                NetbarId: this.netBarId,
                NetbarName: this.netBarName,
                ConsumerId: this.userInfo.ConsumerId,
                Type: this.form.problem,
                Content: this.form.desc,
                OrderNumber: this.form.orderInfo,
                ValidateKey: "",
                Code: "",
              };
              let res = await ProposalAdd(params);
              if (res.StatusCode == 200) {
                this.$message({
                  message: "提交成功",
                  type: "success",
                });
                this.$refs[formName].resetFields();
              }
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        } else {
          eventBus.$emit("showLoginPhone");
        }
      } else {
        eventBus.$emit("getQrCode");
      }
    },
    // 获取短信验证码
    async _GetPhoneCode() {
      this.isDisable = true;
      if (!this.computeTime) {
        let params = {
          phone: this.userInfo && this.userInfo.Phone,
        };
        let res = await GetPhoneCode(params);
        if (res.StatusCode == 200 && res.Object) {
          this.$message({
            message: "发送成功",
            type: "success",
          });
          // 启动倒计时
          clearInterval(this.intervalId);
          this.intervalId = null;
          this.computeTime = 60;
          this.intervalId = setInterval(() => {
            this.computeTime--;
            if (this.computeTime <= 0) {
              // 停止计时
              this.computeTime = 0;
              this.isDisable = false;
              clearInterval(this.intervalId);
              this.intervalId = null;
            }
          }, 1000);
        } else {
          this.computeTime = 0;
          this.isDisable = false;
          this.$message({
            message: res.Message,
            type: "error",
          });
        }
      }
    },
    // 确认注销
    async _ConfirmLogout() {
      if (!this.form.code) {
        // 未输入手机验证码
        this.$message({
          message: "请输入手机验证码",
          type: "warning",
        });
        return false;
      } else {
        // 获取渠道来源
        let params = {
          consumerId: this.userInfo && this.userInfo.ConsumerId,
          phone: this.userInfo && this.userInfo.Phone,
          phoneCode: this.form.code,
        };
        let res = await LogOff(params);
        if (res.StatusCode == 200) {
          this.checkApplyDialog = false;
          // 成功提示
          this.$message({
            message: "注销成功",
            type: "success",
          });

          this.$store.commit("CLEAR_USER");
          this.$router.push("/home");
        }
        // else {
        //   // 错误提示
        //   this.$message({
        //     message: res.Message,
        //     type: "error",
        //   });
        // }
      }
    },
    slectChanged(val) {
      this.form.problem = this.selectList.find(
        (item) => item.value == val
      ).label;
    },
    // 复制成功时的回调函数
    onCopy(e) {
      this.$message({
        type: "success",
        message: "内容已复制到剪切板！",
      });
    },
    // 复制失败时的回调函数
    onError(e) {
      this.$message.error("抱歉，复制失败！");
      this.$message({
        type: "error",
        message: "抱歉,复制失败！",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-back-wrapper {
  padding: 20px;

  .logOff {
    margin-top: 30px;
    color: #ff3636;
    text-decoration: underline;
    cursor: pointer;
  }

  .feed-back-form {
    height: 676px;
    position: relative;
    @include font_col6();
    @include item_bg_col();

    .historical-feedback {
      width: 200px;
      position: absolute;
      top: 30px;
      right: 40px;
      line-height: 32px;
      text-align: center;
      font-size: 16px;
      // background: #ccc;
    }

    .qrCode {
      width: 200px;
      position: absolute;
      top: 68px;
      right: 40px;
      line-height: 32px;
      text-align: center;
      font-size: 16px;

      img {
        width: 100%;
      }
    }

    ::v-deep .el-form {
      width: 654px;
      // margin: 0 auto;
      margin-left: 100px;
      padding-top: 84px;

      .form-item {
        height: 50px;
        line-height: 50px;
        border: 1px solid #8f8f8f;
        padding-left: 20px;
        font-size: 16px;
        @include font_col3();

        .advice {
          width: 440px;
          float: right;
        }

        .order-info {
          width: 440px;
          float: right;
        }
      }

      .el-input__inner {
        width: 440px;
        height: 48px;
        line-height: 48px;
        border: none;
        @include font_col3();
      }

      .el-textarea__inner {
        border: 1px solid #8f8f8f;
        border-radius: 0;
        @include font_col3();
      }

      .el-button {
        width: 654px;
        background: $red_col;
        color: $white-col;
        border-radius: 0;
        font-size: 18px;
        border: none;
      }

      h3 {
        font-size: 18px;

        .copy {
          margin-left: 20px;
          background: #ff3636;
          color: #fff;
          font-size: 14px;
          padding: 5px 10px;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }

  .visible-cancellation {
    .el-dialog__body {
      p {
        line-height: 26px;
        text-indent: 2em;
      }

      .el-checkbox__label {
        a {
          color: $red_col;
        }
      }

      .check-box {
        margin-top: 20px;
      }
    }

    .el-button--default:focus,
    .el-button--default:hover {
      color: #ff3636;
      border-color: #ff3636;
      background-color: transparent;
    }

    .el-form {
      width: 300px;
      margin: 0 auto;
      padding: 15px 0;
      text-align: left;

      ::v-deep .el-input__inner {
        height: 40px !important;
        line-height: 40px !important;
        background: transparent;
        // border-color: $fontColor;
      }

      .verification-code {
        text-align: left;

        .el-input {
          width: 180px;

          .el-input__inner {
            height: 40px !important;
          }
        }

        .el-button {
          width: 115px;
          height: 40px;
          display: inline-block;
          color: #fff;
          background: $red_col;
          margin-left: 5px;
          text-align: center;
          vertical-align: bottom;
          border: none;
          border-radius: 2px;
          padding: 0;
        }

        .el-button:hover {
          transform: scale(1.05);
        }
      }

      .login {
        width: 300px;
        height: 40px;
        border-radius: 4px;
        display: block;
        background: $red_col;
        color: #fff;
        // font-size: 16px;
        text-align: center;
      }

      .login:hover {
        transform: scale(1.05);
      }
    }
  }
}
</style>
